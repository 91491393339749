.section.catalog-on-free {
	position: relative;
	padding: {
		top: 70px;
		bottom: 0; }
	background-color: #C7B299;
	.bl-form {
		.title {}
		.text {}
		.form {
			.name {}
			.wr-it {} } }
	.bl-img-catalog {
		z-index: 1;
		top: auto;
		margin-right: $catalog-form-w;
		bottom: 0;
		background: url("#{$path-design}katalog.png") left bottom no-repeat;
		background-size: contain;
		.update {
			position: absolute;
			bottom: 104px;
			text-align: center;
			width: 100%;
			font-size: 14px; } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.catalog-on-free {
		position: relative;
		padding-top: 15px;
		.bl-form {
			.title {}
			.text {}
			.form {
				.name {}
				.wr-it {} } }
		.bl-img-catalog {
			display: none;
			.update {
				position: static;
				text-align: center; } } } }
