@import "main";

/* Components */
@import "components/swiper";

/* Pages */
@import "page/first-screen";
@import "page/bl-error";
@import "page/pereplata";
@import "page/projects";
@import "page/projects-popup";
@import "page/five";
@import "page/we-build";
@import "page/negative";
@import "page/postroili";
@import "page/garant";
@import "page/sevenstep";
@import "page/contact";
@import "page/calc";
@import "page/popup-test";
@import "page/one-screen";

/* Forms */
@import "page/form/enroll-on-free";
@import "page/form/catalog-on-free";
@import "page/form/excursion";
@import "page/form/postroil";
