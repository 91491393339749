$c-white: #fff;
$c-orange: #f76209;
$c-black: #000;
$c-gray: #7F7F7F;
$c-gray-light: #CECECE;
$c-gray-light-light: #f7f7f7;
$c-yellow: #FBD46E;
$c-yellow-dark: #FBCD6E;
$c-red: #ed1c24;
$c-red-light: lighten($c-red,5%);
$c-blue: #0071BC;
$c-blue-light: lighten($c-blue,5%);


// $c-primary-rgb: rgb(65, 81, 107)
$c-primary: #A97C50;
$c-primary-dark: #534741;
$c-primary-light: #C3A385;

$c-background: #F6F7F9;
/*Text*/
$c-text: #2F2F2F;
$c-text-gray: #CECECE;

/*btn*/
$c-btn: #534741;
$c-btn-hover: lighten($c-btn,5%);
$c-btn-active: lighten($c-btn,5%);
/*btn white*/
$c-btn-white: #fff;
$c-btn-white-hover: #A97C50;
$c-btn-white-active: #fff;

$c-btn-box-shadow: rgba(127,127,127,0.41);

/*Link*/
$c-link: $c-primary-dark;
$c-link-hover: $c-primary;

$c-bg-gray: #F6F6F6;
$c-bg-white: $c-white;

$c-line: #CECECE;
$c-line-light: #e5e5e5;
$c-error: $c-red;
$c-radio-bg: $c-primary-light;
