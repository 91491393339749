


/* form-------------*/
.form {
	margin: 0;
	max-width: 350px;
	label.error {
		box-shadow: {} } }
.fi-d {
	display: inline-block;
	border: 0;
	background-color: transparent;
	max-width: 100%; }
div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ok,
span.wpcf7-not-valid-tip {
	display: none !important; }

.a-d[data-fancybox] {
	position: relative;
	height: auto;
	width: auto;
	display: inline-block;
	.img {
		display: block; }
	&:after {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		background: rgba(169, 124, 80, 0.43) url('#{$path-design}/search.svg') center center no-repeat;
		background-size: 90% 90%;
		transition: .3s; }
	&:hover:after {
		opacity: 1;
		-webkit-background-size: 50px 50px;
		background-size: 50px 50px; } }

.ir-d {
	display: none; }
.ichb-d {
	display: none;
	.box {} }
.cn3bie-checkbox__wr {
	position: relative;
	padding-left: 20px;
	margin-top: 15px;
	display: block;
	cursor: pointer;
	.cn3bie-checkbox__input {
		display: none;
		&:checked+.cn3bie-wr_label {
			.cn3bie-checkbox__box {
				background: $c-gray-light url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K'); }
			.cn3bie-checkbox__label {
				color: $c-text; } } }
	.cn3bie-checkbox__box {
		position: absolute;
		left: 0;
		top: 0;
		width: 14px;
		height: 14px;
		background: $c-error;
		transition: .3s;
		display: inline-block; }
	.cn3bie-checkbox__label {
		display: block;
		line-height: 14px;
		color: $c-error;
		font-size: 12px;
		a {
			color: $c-blue; } } }

@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.col.s0 {
		display: none; }
	.ir-d {}
	.ichb-d {
		.box {} }
	.cn3bie-checkbox__wr {
		.cn3bie-checkbox__input {
			&:checked+.cn3bie-checkbox__box {} }
		.cn3bie-checkbox__box {}
		.cn3bie-checkbox__label {
			padding-bottom: 10px;
			a {} } } }



.of {
	overflow: hidden; }

.db {
	display: block; }
.dib {
	display: inline-block;
	vertical-align: bottom; }
.p0 {
	padding: 0; }
.strict.p0 {
	padding: 0 !important; }
#main {
	min-height: 100%;
	padding: $header-h 0 $footer-h 0;
	width: 100%;
	position: relative;
	z-index: 1; }
.catalog #main {
	padding-bottom: 0; }
.fullscreen-h {
	height: 100%;
	max-height: 100%; }

@media #{$medium-and-down} {
	#main.main {
		padding: 0 !important; } }

.wr-js-full-h {
	.js-full-h {
		position: absolute;
		top: 0;
		left: 0;
		right: 0; } }

.wr {
	width: 100%;
	height: 100%;
	max-width: $wr-w;
	margin: auto;
	padding: 0 $wr-lr-p; }
.section {
	padding: 0 0 40px 0;
	width: 100%;
	overflow: hidden;
	&.p0 {
		padding: 0; }
	&.row {
		.col.m6 {
			&:nth-of-type(odd), {
				.wr {
					float: right;
					max-width: $wr-w /2;
					padding-left: $wr-lr-p; } }
			&:nth-of-type(even), {
				.wr {
					float: left;
					max-width: $wr-w /2;
					padding-right: $wr-lr-p; } } } } }
@media #{$medium-and-down} {
	.section {
		padding-bottom: 20px; } }
@media #{$small-and-down} {
	.section {
		padding-bottom: 10px; }
	.wr-js-full-h {
		.js-full-h {
			position: static; } } }
@media screen {
	@media (min-width: $wr-w + $wr-lr-p * 2) {
		.wr {
			padding: 0; }
		.section {
			&.p0 {}
			&.row {
				.col.m6 {
					&:nth-of-type(odd), {
						.wr {
							padding-left: 0; } }
					&:nth-of-type(even), {
						.wr {
							padding-right: 0; } } } } } } }

.dn {
	display: none; }
.bgg {
	background-color: $c-bg-gray; }
.bgw {
	background-color: $c-bg-white; }

.btni-download {
	text-decoration: underline;
	color: $c-primary;
	line-height: {}
	font-size: 16px;
	line-height: 19px; }
ul.ul-d,
li.li-d {
	margin: 0;
	padding: 0;
	list-style-type: none; }

ul.ul-d li.li-d {
	list-style: none; }
.fl {
	float: left; }
.fr {
	float: right; }
.posf {
	position: fixed; }
.posa {
	position: absolute; }
.posf,
.posa {
	&.lt {
		top: 0;
		left: 0;
		right: auto;
		bottom: auto; }
	&.lb {
		top: auto;
		left: 0;
		right: auto;
		bottom: 0; }
	&.rt {
		top: 0;
		left: auto;
		right: 0;
		bottom: auto; }
	&.rb {
		top: auto;
		left: auto;
		right: 0;
		bottom: 0; } }
.posr {
	position: relative; }
/*Дизайнерские буквы по бокам*/
$letter-w: 217px;
$letter-h: 191px;
.wr-letter {
	position: relative;
	.letter-d {
		display: inline-block;
		position: absolute;
		width: $letter-w;
		height: $letter-h;
		line-height: $letter-h;
		text-transform: uppercase;
		text-align: center;
		color: $c-text-gray;
		font-size: 250px;
		font-weight: 700;
		opacity: 0.1;
		&.lt {
			left: -$letter-w / 2;
			top: 0;
			text-align: left; }
		&.lb {
			left: -$letter-w / 2;
			bottom: 0;
			text-align: left; }
		&.rt {
			right: -$letter-w / 2;
			top: 0;
			text-align: right; }
		&.rb {
			right: -$letter-w / 2;
			bottom: 0;
			text-align: right; } } }

@media #{$medium-and-down} {
	.wr-letter {
		.letter-d {
			display: none; } } }
@media #{$small-and-down} {}

.scrollspy {}
.wr-scrollspy,
.dno-scrollspy {
	display: block;
	width: 100%;
	min-height: 1px; }

.pf,
.pf-h,
.pf-v {
	position: fixed; }
.pf-h {
	left: 0;
	right: 0; }
.pf-v {
	top: 0;
	bottom: 0; }

$ic-w: 20px;
$ic-h: $ic-w;
.ic,
.ic-t,
.bl-img {
	&.letter {
		background-image: url("#{$path-design}letter.svg"); }
	&.arrows {
		background-image: url("#{$path-design}arrows.png"); }
	&.graphic-design {
		background-image: url("#{$path-design}graphic-design.png"); }
	&.price-tag {
		background-image: url("#{$path-design}price-tag.png"); }
	&.socialmedia {
		background-image: url("#{$path-design}socialmedia.png"); }
	&.close {
		background-image: url("#{$path-design}close.svg"); }
	&.sort {
		background-image: url("#{$path-design}switch-sort.png"); }
	&.back {
		background-image: url("#{$path-design}back.svg"); }
	&.book-apply {
		background-image: url("#{$path-design}book-apply.svg"); }
	&.builder {
		background-image: url("#{$path-design}builder.svg"); }
	&.flag {
		background-image: url("#{$path-design}flag.svg"); }
	&.house-shite {
		background-image: url("#{$path-design}house-shite.svg"); }
	&.house-shite-w700 {
		background-image: url("#{$path-design}house-shite-w700.svg"); }
	&.machine {
		background-image: url("#{$path-design}machine.svg"); }
	&.maps {
		background-image: url("#{$path-design}maps.svg"); }
	&.marker {
		background-image: url("#{$path-design}marker.svg"); }
	&.next {
		background-image: url("#{$path-design}next.svg"); }
	&.old-watch {
		background-image: url("#{$path-design}old-watch.svg"); }
	&.paper-clock {
		background-image: url("#{$path-design}paper-clock.svg"); }
	&.paper-apply {
		background-image: url("#{$path-design}paper-apply.svg"); }
	&.photo {
		background-image: url("#{$path-design}photo.svg"); }
	&.scheme {
		background-image: url("#{$path-design}scheme.svg"); }
	&.the-table {
		background-image: url("#{$path-design}the-table.svg"); }
	&.watch {
		background-image: url("#{$path-design}watch.svg"); }
	&.pencils {
		background-image: url("#{$path-design}pencils.svg"); }
	&.gift {
		background-image: url("#{$path-design}gift.svg"); }
	&.yandex {
		background-image: url("#{$path-design}yandex.png"); }
	&.mail {
		background-image: url("#{$path-design}mail.png"); }
	&.gmail {
		background-image: url("#{$path-design}gmail.png"); } }
.ic,
.ic-t {
	display: inline-block;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	width: $ic-h;
	height: $ic-w;
	margin: 0 5px;
	vertical-align: bottom;
	position: relative;
	text-decoration: none;
	color: $c-black;
	&.marker {}
	&.printer {}
	&.delivery {
		background-size: 42px auto; }
	&.diagram {
		background-size: 35px 35px; }
	&.key-arm {
		background-size: 42px auto; }
	&.shield {
		background-size: 34px 37px; }
	&.heart {
		&.active {} }
	&.basket {
		&.active {} }
	&.jackdaw,
	&.close,
	&.prev,
	&.next,
	&.down,
	&.up, {
		position: relative;
		background: transparent;
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%; } }
	&.close {
		&:before,
		&:after {
			height: 1px;
			border-radius: 5px;
			background-color: $c-primary;
			left: 0;
			right: 0;
			margin: auto;
			top: 0;
			bottom: 0; }
		&:before {
			transform: rotate(45deg); }
		&:after {
			transform: rotate(-45deg); }
		&.v1 {
			&:before,
			&:after {
				background-color: $c-white; } } }
	&.prev,
	&.next,
	&.down,
	&.up {
		&:before,
		&:after {
			width: 15px;
			height: 1px;
			border-radius: 5px;
			background-color: $c-gray;
			top: 50%; } }
	&.prev {
		&:before,
		&:after {
			left: 50%;
			transform-origin: left bottom;
			margin-left: -5px; }
		&:before {
			transform: rotate(45deg); }
		&:after {
			transform: rotate(-45deg); } }
	&.next {
		&:before,
		&:after {
			right: 50%;
			transform-origin: right bottom;
			margin-right: -5px; }
		&:before {
			transform: rotate(45deg); }
		&:after {
			transform: rotate(-45deg); } }
	&.down {
		margin: 0;
		width: 10px;
		&:before,
		&:after {
			top: 50%;
			transition: .3s; }
		&:before {
			right: -8px;
			transform: rotate(45deg); }
		&:after {
			left: -8px;
			transform: rotate(-45deg); }
		&.active {
			&:before {
				transform: rotate(-45deg); }
			&:after {
				transform: rotate(45deg); } } }
	&.jackdaw {
		margin: 0 18px 0 0;
		transform: rotate(-45deg) translate(7px,-7px);
		&:before,
		&:after {
			border-radius: 3px;
			bottom: 0;
			left: 0;
			background-color: $c-primary; }
		&:before {
			height: 50%;
			width: 2px; }
		&:after {
			height: 2px; } } }

.ic-t {
	width: auto;
	background-position: center left;
	padding-left: $ic-w + 5px;
	line-height: $ic-h; }

.line-bottom {
	border-bottom: $border-line; }

.img {}

.bl-img,
.bl-img-bg {
	display: block;
	margin: auto;
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	min-height: $img-min-h; }
.bl-img {
	background-size: contain;
	max-width: $img-min-w; }
.bl-img-bg {
	background-size: cover; }

.badget {
	display: none;
	position: absolute;
	width: $badget-h;
	height: $badget-w;
	line-height: $badget-h+2px;
	vertical-align: bottom;
	top: -$badget-h + 5px;
	right: -$badget-h + 5px;
	font-size: 12px;
	border-radius: 50%;
	background-color: $c-yellow;
	left: auto;
	bottom: auto;
	text-align: center;
	text-align: -webkit-center; }


.textarea {
	width: 100%;
	max-width: 100%;
	color: $c-text;
	background-color: $c-white;
	font-size: 16px;
	line-height: 19px;
	padding: 15px;
	border: 0;
	resize: none;
	min-height: 8em;
	height: auto;
	border-radius: 3px;
	overflow: hidden;
	transition: .3s ease;
	&.wpcf7-not-valid {
		color: $c-red !important;
		border: 1px solid $c-red !important;
		box-shadow: inset 0 0 9px $c-red !important; } }
.wr-if {
	text-decoration: underline;
	padding: 10px 0 30px;
	cursor: pointer;
	&:active {
		color: $c-primary; }
	&.wpcf7-not-valid {
		color: $c-red !important; } }
.wr-if .if {
	display: none; }
.wr-it input.it,
.it {
	display: inline-block;
	width: 100%;
	padding: 0 20px;
	background: transparent;
	transition: .3s ease;
	color: $c-text;
	line-height: 19px;
	font-size: 16px;
	text-align: left;
	box-sizing: border-box;
	height: $btn-h;
	line-height: $btn-h;
	&.wpcf7-not-valid {
		color: $c-red !important;
		border: 1px solid $c-red !important;
 } }		// box-shadow: inset 0 0 9px $c-red !important
.wr-it {
	background-color: $c-white;
	border: 1px solid $c-gray-light;
	max-width: 100%;
	width: 100%;
	margin-bottom: 10px;
	border-radius: 0;
	input.it {
		&,
		&:hover,
		&:active,
		&:focus, {
			width: 100%;
			height: $btn-h - 2px;
			margin: 0;
			border: 0 !important;
			box-shadow: none !important; }
		&::placeholder {
			color: $c-text-gray; } } }
button.btn {
	padding: 0;
	max-width: 100%;
	width: 100%; }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.wr-if {
		padding: 10px 0 20px;
		&:active {}
		&.wpcf7-not-valid {} }
	.wr-if .if {}
	.wr-it {
		input.it {
			&,
			&:hover,
			&:active,
			&:focus, {
				width: 100%;
				// height: $btn-h * .8 - 2px
 }				// line-height: $btn-h * .8 - 2px
			&::placeholder {} } } }


.pow {
	font-size: .7em;
	position: relative;
	bottom: .5em; }

.br {
	display: block;
	position: relative !important;
	height: 1px;
	width: 1px; }

.bg-cover {
	background: transparent center center no-repeat;
	-webkit-background-size: cover;
	background-size: cover; }

.bg-contain {
	background: transparent center center no-repeat;
	-webkit-background-size: contain;
	background-size: contain; }

.row {
	&.m0 {
		margin: 0; }
	.col {
		&.m0 {
			margin: 0; }
		&.p0 {
			padding: 0; } } }

.before {
	position: relative;
	&:before {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute; } }
.after {
	position: relative;
	&:after {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute; } }
.beaf {
	@extend .after;
	@extend .before; }

// .bl-dent
// 	-webkit-transition:all .2s ease-out
// 	-moz-transition:all .2s ease-out
// 	-ms-transition:all .2s ease-out
// 	-o-transition:all .2s ease-out
// 	transition:all .2s ease-out
// 	&:active
// 		-webkit-transform: scale(0.95)
// 		-moz-transform: scale(0.95)
// 		-o-transform: scale(0.95)

// .bl-brick
// 	display: inline-block
// 	background-color: $c-white
// 	vertical-align: bottom
// 	width: $brick-side + $brick-border-weight
// 	height: $brick-side + $brick-border-weight
// 	border: $brick-border-weight solid $c-primary
// 	text-align: center
// 	line-height: $brick-side

.text.min {
	line-height: 21px;
	font-size: 14px; }
.section.bl-text {
	.title {
		text-align: center;
		line-height: 43px;
		font-size: 36px; }
	.text {
		max-width: 90%;
		margin: auto;
		p {
			line-height: 19px;
			font-size: 16px;
			text-align: justify; } } }


@media #{$medium-and-down} {}
@media #{$small-and-down} {
	// .text
	// 	font-size: 12px
 }	// 	line-height: 14px


/* Z-levels-------------*/
.zd-0 {
	box-shadow: none !important; }
.zd-1 {
	box-shadow: 0px 8px 14px rgba(127,127,127,0.25); }
.zd-2 {
	box-shadow: 0px 8px 14px rgba(127,127,127,0.4); }
// .zd-3
// 	box-shadow: 3px 3px 8px rgba(127, 127, 127, 0.3), -3px 3px 8px rgba(127, 127, 127, 0.3)
// .zd-4
// 	box-shadow: 4px 4px 8px rgba(127, 127, 127, 0.3), -4px 4px 8px rgba(127, 127, 127, 0.3)
.zd-1010 {
	-webkit-box-shadow: 0 20px 60px 0 rgba(159,164,189,0.5);
	box-shadow: 0 20px 60px 0 rgba(159,164,189,0.5); }
/* moveup -------------------------------------------------------------------------------*/
#moveup {
	width: 55px;
	height: 55px;
	position: fixed;
	bottom: 5%;
	right: 5%;
	background: $c-primary;
	border-radius: 50%;
	z-index: 10;
	line-height: 40px;
	.ic {
		&:before,
		&:after {
			background-color: $c-white; } } }
@media #{$small-and-down} {}
@media #{$medium-and-down} {
	#moveup {
		display: none; } }


/*При разных размерах областей*/

@media #{$large-and-up} {
	.transfer.col {
		&.l6:nth-of-type(odd),
		&.l4:nth-of-type(3n+1),
		&.l3:nth-of-type(4n+1) {
			clear: both; } }
	// .justify-card.col
	// 	.item-card
	// 		margin: auto
	// 	&.l4:nth-of-type(3n)
	// 		.item-card
	// 			float: right
	// 	&.l4:nth-of-type(3n+1)
	// 		.item-card
 }	// 			float: left

@media #{$medium-only} {
	.transfer.col {
		&.l6:nth-of-type(odd),
		&.l4:nth-of-type(3n+1),
		&.l3:nth-of-type(3n+1) {
			clear: none; } }
	.transfer.col {
		&.m6:nth-of-type(odd),
		&.m4:nth-of-type(3n+1),
		&.m3:nth-of-type(4n+1) {
			clear: both; } } }

$side-w: 26px;
.wr-jackdaw {
	margin-bottom: 15px;
	label {
		line-height: $side-w + 6px;
		color: $c-text;
		font-size: 16px;
		&:before,
		&:after {
			content: none; }
		.jackdaw {
			position: absolute;
			left: 0;
			width: $side-w;
			height: $side-w;
			border: 3px solid $c-gray-light;
			transform: rotate(0) translate(0,0);
			margin: 0;
			transition: .3s;
			&:before,
			&:after {
				top: auto;
				left: auto;
				right: -3px;
				bottom: -3px;
				background-color: $c-gray-light;
				transition: .3s; }
			&:before {
				width: auto;
				height: 3px;
				left: -3px; }
			&:after {
				height: auto;
				width: 3px;
				top: -3px; } } }
	input:checked+label {
		&:before,
		&:after {
			content: none; }
		.jackdaw {
			transform: rotate(45deg) translate(2px, -11px);
			width: $side-w /2;
			border-color: $c-white;
			&:before,
			&:after {
				background-color: $c-primary; } } }
	input:disabled+label {
		color: $c-gray-light; } }

@media #{$small-and-down} {}
@media #{$medium-and-down} {}
// 	.tcon
// 		height: 30px
// 		.tcon-menu__lines
// 			width: 30px
// 		.tcon-menu__lines,
// 		.tcon-menu__lines:after,
// 		.tcon-menu__lines:before
// 			height: 2px
// 		.tcon-menu__lines:before
// 			top: 8px
// 		.tcon-menu__lines:after
// 			top: -8px

// .snarl-notification
// 	box-shadow: none
// 	border-radius: 0

.tooltipster-sidetip {
	pointer-events: visible;
	.tooltipster-box {
		border: 1px solid $c-white !important;
		background-color: $c-primary !important; }
	&.tooltipster-top {
		.tooltipster-arrow-border {
			border-top-color: $c-white !important; }
		.tooltipster-arrow-background {
			border-top-color: $c-primary !important; } }
	&.tooltipster-bottom, {
		.tooltipster-arrow-border {
			border-bottom-color: $c-white !important; }
		.tooltipster-arrow-background {
			border-bottom-color: $c-primary-light !important; } } }





.show-active {
	display: none; }
.active {
	.show-active {
		display: block; }
	.hide-active {
		display: none; } }
