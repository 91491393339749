.section.garant {
	.wr {
		.title {
			line-height: 50px;
			font-size: 42px;
			padding-bottom: 0; }
		&>.text {
			text-align: center; }
		.row {
			margin-top: 50px;
			.col {
				.bl-img {
					margin: 0;
					min-height: 51px;
					max-width: 51px; }
				.name {
					margin-top: 10px;
					line-height: 19px;
					font-weight: 700; }
				.text {
					margin-top: 10px;
					line-height: 19px; } } }
		.bl-btn {
			text-align: center;
			position: relative;
			max-width: 318px;
			margin: 60px auto 0;
			.arrow {
				background: url("#{$path-design}arrow.png");
				position: absolute;
				left: -94px;
				top: -35px;
				height: 93px;
				width: 73px;
				transform: rotate(-13deg); }
			.btn {
				padding: 25px 0;
				font-size: 12px;
				line-height: 14px;
				border: 2px solid $c-text;
				&:hover {
					background-color: $c-primary-dark;
					border-color: $c-primary-dark; }
				span {
					display: inline-block;
					margin-bottom: 8px;
					font-size: 16px;
					line-height: 19px;
					font-weight: 700; } } }
		.bundle {
			margin: 60px auto 30px;
			border-radius: 50%;
			width: 260px;
			height: 260px;
			background-color: $c-primary;
			font-size: 32px;
			line-height: 32px;
			text-align: center;
			color: $c-white;
			padding: 63px 0;
			&>span {
				font-size: 54px;
				line-height: 54px;
				font-weight: 700;
				display: block;
				span {
					font-size: 92px;
					letter-spacing: 6px;
					line-height: 92px; } } }
		.text {
			line-height: 22px; } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.garant {
		.wr {
			.title {
				line-height: 1.3;
				font-size: 30px;
				text-align: left; }
			.text {
					line-height: 1.3;
					text-align: left; }
			.row {
				margin-top: 20px;
				.col {
					.bl-img {
						margin: 0 10px 0 0;
						float: left; }
					.name {
						margin-top: 0;
						line-height: 1.3; }
					.text {
						margin: 10px 0 10px 61px;
						line-height: 1.3; } } }
			.bl-btn {
				max-width: 100%;
				margin: 10px auto 0;
				.arrow {
					display: none; }
				.btn {
					padding: 10px 0;
					&:hover {}
					span {} } }
			.bundle {
				margin: 10px auto;
				padding: 11px 0;
				height: 85px;
				width: 85px;
				font-size: 14px;
				line-height: 1;
				&>span {
					display: inline-block;
					margin-right: 5px;
					font-size: 16px;
					line-height: 1;
					span {
						font-size: 34px;
						line-height: 1; } } } } } }
