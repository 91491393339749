.section.projects {
	.wr {
		.title {
			line-height: 50px;
			font-size: 42px;
			font-weight: 700;
			text-align: center;
			padding-bottom: 15px;
			padding-top: 76px; }
		.text {
			text-align: center; }
		.wr-card {
			margin-top: 46px;
			.col {
				padding-bottom: 15px;
				&:nth-of-type(3n) {
					.item-card {
						margin-right: 0; } }
				&:nth-of-type(3n+1) {
					.item-card {
						margin-left: 0; } }
				.item-card {
					max-width: 330px;
					margin: auto;
					background-color: $c-white;
					.bl-img {
						max-width: 100%;
						min-height: 247px;
						background-size: cover; }
					.info {
						padding: 25px 15px;
						.name {
							display: block;
							text-align: center;
							font-weight: 700;
							line-height: 24px;
							font-size: 20px; }
						.ul-d {
							margin-top: 15px;
							.li-d {
								margin-bottom: 5px;
								span {
									float: right;
									font-weight: 700; } } }
						.price {
							text-align: center;
							margin-top: 15px;
							line-height: 17px;
							font-size: 14px;
							span {
								font: {
									size: 20px;
									weight: 700; }
								line-height: 24px; } }
						.a-d {
							margin-top: 20px;
							text-align: center;
							color: $c-primary;
							text-decoration: underline; } } } } } } }

@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.projects {
		.wr {
			.title {
				line-height: 1.3;
				font-size: 30px;
				padding-top: 15px;
				text-align: left; }
			.text {
				text-align: left; }
			.wr-card {
				margin-top: 15px;
				.col {
					&:nth-of-type(3n) {
						.item-card {} }
					&:nth-of-type(3n+1) {
						.item-card {} }
					.item-card {
						.bl-img {}
						.info {
							.name {}
							.ul-d {
								.li-d {
									span {} } }
							.price {
								span {} }
							.a-d {} } } } } } } }
