.section.negative {
	.wr {
		max-width: 800px; }
	.title {
		text-align: center;
		line-height: 50px;
		font-size: 42px; }
	.ul-d {
		.li-d.row {
			margin-bottom: 50px;
			.col {
				position: relative;
				.number {
					position: relative; }
				.bl-img {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: auto; }
				.bl-img {
					bottom: 0; }
				.number {
					display: inline-block;
					line-height: 182px;
					color: $c-primary;
					font-size: 182px;
					text-align: center;
					font-weight: 700;
					span {
						opacity: 0.2; } } }
			.name {
				font-weight: 700;
				line-height: 24px;
				font-size: 20px; }
			.text {
				line-height: 19px; }
			.btn {
				display: inline-block;
				width: auto;
				padding: {
					left: 30px;
					right: 30px; }
				min-height: 48px;
				line-height: 48px;
				font-size: 14px; }
			&.neg-1 {
				.bl-img {
					max-width: 66px;
					min-height: 64px;
					margin-bottom: -28px; } }
			&.neg-2 {
				.col {
					text-align: right;
					.number {
						line-height: 100px;
						.bl-img {} } } }
			&.neg-3 {
				.col {
					text-align: left;
					.number {
						line-height: 150px;
						.bl-img {
							max-width: 57px;
							min-height: 47px; } }
					.wr-thumb {
						margin-left: -5px;
						margin-top: 20px;
						.thumb {
							float: left;
							max-width: 142px;
							margin: 5px;
							cursor: pointer; } } } }
			&.neg-4 {
				margin-top: 70px;
				.col {
					text-align: right;
					.number {
						line-height: 90px;
						.bl-img {} } } } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.negative {
		.wr {}
		.title {
			line-height: 1.3;
			font-size: 30px;
			text-align: left; }
		.ul-d {
			.li-d.row {
				margin-bottom: 10px;
				.col {}
				.name {
					font-weight: 700;
					line-height: 24px;
					font-size: 20px; }
				.text {
					line-height: 1.3; }
				.btn {}
				&.neg-1 {
					.bl-img {} }
				&.neg-2 {
					.col {
						.number {
							.bl-img {} } } }
				&.neg-3 {
					.col {
						.number {
							.bl-img {} }
						.wr-thumb {
							display: none;
							margin: {
								left: 0;
								top: 10px; }
							.thumb {
								max-width: 48%;
								min-height: 108px;
								margin: 1%; } } } }
				&.neg-4 {
					margin-top: 10px;
					.col {
						.number {
							.bl-img {} } } } } } } }
