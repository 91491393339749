@charset "UTF-8";

/* Grid Для работы медиа запросов*/

// Color
@import "components/color_global";
// Variables
@import "components/variables_global";
// Reset
@import "components/normalize";
// Transition
@import "components/transitions";
// Global
@import "components/global";
// Grid
@import "components/grid";

// Components

/* Components of page */
@import "components/color";
@import "components/variables";
@import "components/fonts";
@import "components/reset";
@import "components/components";
@import "components/text";
@import "components/btn";
@import "components/header";
@import "components/footer";
@import "components/fancybox";
@import "components/mixin";
@import "components/iziModal";

/* Forms */
@import "page/form/feedback";

/* Pages */
@import "page/common";
@import "page/thanks";
@import "page/thanks-catalog";
