@font-face {
	font: {
		family: 'Gotham Pro';
		weight: 400;
		style: normal; }
	src: url('../fonts/gothampro.eot');
	src: local('☺'), url('../fonts/gothampro.woff') format('woff'), url('../fonts/gothampro.svg') format('svg'); }
@font-face {
	font: {
		family: 'Museo Sans';
		weight: 300;
		style: normal; }
	src: url('../fonts/museosansregular.eot');
	src: local('☺'), url('../fonts/museosansregular.woff') format('woff'), url('../fonts/museosansregular.svg') format('svg'); }
@font-face {
	font: {
		family: 'Gotham Pro';
		weight: 700;
		style: normal; }
	src: url('../fonts/gothamprobold.eot');
	src: local('☺'), url('../fonts/gothamprobold.woff') format('woff'), url('../fonts/gothamprobold.svg') format('svg'); }
@font-face {
	font: {
		family: 'Gotham Pro';
		weight: 400;
		style: italic; }
	src: url('../fonts/gothamproitalic.eot');
	src: local('☺'), url('../fonts/gothamproitalic.woff') format('woff'), url('../fonts/gothamproitalic.svg') format('svg'); }
