$catalog-form-w: 404px;

.wr-form-bg {
	position: relative;
	.wr-bl-form-notice {
		padding-right: $catalog-form-w;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		.title {
			text-align: left; } } }
.bl-form {
	position: relative;
	padding-bottom: 60px;
	max-width: $catalog-form-w;
	float: right;
	width: 100%;
	z-index: 2;
	.title {
		text-align: left;
		padding: {
			top: 0;
			bottom: 15px; }
		line-height: 40px;
		font-size: 36px; }
	.text {
		margin-bottom: 30px; }
	.form {
		max-width: 350px;
		position: relative;
		.name {
			margin-bottom: 5px; }
		.wr-it {
			margin-bottom: 15px; } } }
.pending {
	display: none;
	position: absolute;
	background: rgba(255,255,255,.6);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	svg.preloader {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100px;
		height: 100px;
		margin: auto; } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.wr-form-bg {
		.wr-bl-form-notice {
			padding: 0; }
		.bl-form {
			padding-bottom: 10px;
			max-width: 100%;
			float: none;
			.title {
				line-height: 1.3;
				font-size: 30px; }
			.text {
				margin-bottom: 10px; }
			.form {
				.name {}
				.wr-it {} } } } }
