#popup-feedback {
	max-width: 520px !important;
	.wr-content {
		padding: 62px 84px 35px 83px;
		float: none;
		max-width: 100%;
		.title {
			padding: 0;
			line-height: 40px;
			font: {
				size: 36px;
				weight: 700; } }
		.text {
			padding-bottom: 20px;
			text-align: center; }
		.form {
			.wr-switcher {
				display: block;
				.text {
					text-align: center;
					line-height: 19px;
					margin: 0 0 10px;
					padding: 0; }
				.row {
					border: {
						left: $border-line-light;
						top: $border-line-light;
						bottom: $border-line-light; } }
				.switcher {
					border-right: $border-line-light;
					cursor: pointer;
					display: block;
					font-size: 14px;
					text-align: center;
					min-height: 35px;
					line-height: 35px; }
				.wr-it-time {
					display: none; }
				.switcher-radio:checked+.switcher {
					background-color: $c-bg-gray; } } } } }

@media #{$medium-and-down} {}
@media #{$small-and-down} {
	#popup-feedback {
		max-width: 100%;
		.wr-content {
			padding: 30px 10px 10px;
			.title {
				padding: 0;
				line-height: 1.3;
				font-size: 30px; }
			.text {
				padding-bottom: 20px;
				text-align: left; }
			.form {
				.wr-switcher {
					.text {}
					.row {}
					.switcher {}
					.wr-it-time {}
					.switcher-radio:checked+.switcher {} } } } } }
