
$title-fs: 42px;
$title-lh: 50px;

.title {
	font: {
		size: $title-fs;
		weight: 700; }
	line-height: $title-lh;
	text-align: center;
	margin: 0;
	padding: {
		top: 46px;
		bottom: 39px; } }

h1,h1.title {
	line-height: $lh-h1;
	font-size: $fs-h1; }
h2,h2.title {
	line-height: $lh-h2;
	font-size: $fs-h2; }
h3,h3.title {
	line-height: $lh-h3;
	font-size: $fs-h3; }
h4,h4.title {
	line-height: $lh-h4;
	font-size: $fs-h4; }
h5,h5.title {
	line-height: $lh-h5;
	font-size: $fs-h5; }
h6,h6.title {
	line-height: $lh-h6;
	font-size: $fs-h6; }
@media #{$medium-and-down} {
	.title {
		font-size: $title-fs * 0.8;
		line-height: $title-lh * 0.8;
		padding-top: 26px;
		padding-bottom: 19px; }
	h1,h1.title {
		line-height: $lh-h1 * 0.8;
		font-size: $fs-h1 * 0.8; }
	h2,h2.title {
		line-height: $lh-h2 * 0.8;
		font-size: $fs-h2 * 0.8; }
	h3,h3.title {
		line-height: $lh-h3 * 0.8;
		font-size: $fs-h3 * 0.8; }
	h4,h4.title {
		line-height: $lh-h4 * 0.8;
		font-size: $fs-h4 * 0.8; }
	h5,h5.title {
		line-height: $lh-h5 * 0.8;
		font-size: $fs-h5 * 0.8; }
	h6,h6.title {
		line-height: $lh-h6 * 0.8;
		font-size: $fs-h6 * 0.8; } }
@media #{$small-and-down} {
	.title {
		font-size: 16px !important;
		line-height: 1.3 !important;
		padding-top: 16px;
		padding-bottom: 9px; }
	h1,h1.title {
		line-height: $lh-h1 * 0.6;
		font-size: $fs-h1 * 0.6; }
	h2,h2.title {
		// font-size: 20px !important
		// line-height: 1.3 !important
		text-align: center; }
	h3,h3.title {
		line-height: $lh-h3 * 0.6;
		font-size: $fs-h3 * 0.6; }
	h4,h4.title {
		line-height: $lh-h4 * 0.6;
		font-size: $fs-h4 * 0.6; }
	h5,h5.title {
		line-height: $lh-h5 * 0.6;
		font-size: $fs-h5 * 0.6; }
	h6,h6.title {
		line-height: $lh-h6 * 0.6;
		font-size: $fs-h6 * 0.6; } }

.tal {
	text-align: left; }
.tac {
	text-align: center; }
.tar {
	text-align: right; }

.a-d {
	display: block;
	height: 100%;
	width: 100%;
	color: $c-text;
	text-decoration: none;
	&.phone {
		display: inline; }
	&:focus,
	&:active,
	&:hover {
		text-decoration: none; } }

.marker {
	background-color: #e2d3c5;
	&.yellow {
		background-color: #fff6bb !important; } }

@media #{$small-and-down} {
	h1, h2, h3, h4, h5, h6, p, li, body {
		font-size: 14px !important;
		line-height: 1.3 !important; }
	p, li,
	.text,
	.name,
	.li-d {
		line-height: 1.3 !important;
		font-size: 14px !important; } }
