.wr-swiper {
	.swiper-container {
		.swiper-wrapper {
			transition: .3s;
			.swiper-slide {
				text-align: center;
				.img {
					margin: auto; } } } }
	.wr-thumb {
		position: relative;
		overflow: hidden;
		margin: {
			left: -5px;
			right: -5px; }
		.bl-img {
			background-size: cover;
			float: left;
			max-width: 142px;
			min-height: 108px;
			margin: 5px;
			cursor: pointer; }
		.border-thumb {
			top: 0;
			left: 0;
			position: absolute;
			opacity: .3;
			background-color: $c-primary;
			transition: .3s; } } }
