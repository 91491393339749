#header.main-header {
	padding-top: $header-pt;
	margin-bottom: -$header-h;
	position: relative;
	z-index: 2;
	.bl-logo {
		float: left;
		.a-logo {
			background-image: url("#{$path-design}/logo.svg");
			height: $logo-h;
			width: $logo-w; } }
	.bl-phone {
		padding-top: 15px;
		float: right;
		text-align: right;
		.phone {
			display: block;
			text-align: right;
			line-height: 30px;
			font-size: 20px; }
		.btn {
			max-width: 133px;
			min-height: 37px;
			line-height: 37px;
			font-size: 14px; } }
	.bl-text {
		font-size: 14px;
		padding-top: 30px;
		text-align: center;
		line-height: 18px;
		font-weight: 400; } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	#header.main-header {
		height: auto;
		padding-top: 0;
		margin: 0;
		.bl-logo {
			width: 30%;
			padding-top: 10px;
			.a-logo {
				height: 58px;
				background-position: center top;
				width: 100%;
				margin: auto; } }
		.bl-phone {
			margin-left: 30%;
			float: none;
			padding: {
				left: 10px;
				top: 10px; }
			.phone {
				text-align: right;
				font-size: 16px;
				line-height: 20px; }
			.btn {
				margin-top: 5px; } }
		.bl-text {
			display: none; } } }
