.section.five {
	padding: 0;
	.title {
		padding-top: 90px;
		text-align: center;
		max-width: 800px;
		margin: auto; }
	.ul-d {
		text-align: center;
		.li-d {
			display: inline-block;
			width: 32%;
			min-height: 170px;
			overflow: hidden;
			.wr-info-1 {
				max-width: 137px;
				margin: auto; }
			.wr-info-2 {}
			.wr-info-3 {
				max-width: 174px;
				margin: auto; }
			.wr-info-4 {
				max-width: 200px;
				margin: auto; }
			.wr-info-5 {
				max-width: 219px;
				margin: auto; }
			.bl-img {
				max-width: 61px;
				min-height: 70px;
				margin-bottom: 15px; }
			.name {
				display: block;
				text-align: center;
				font-weight: 700;
				line-height: 19px; } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.five {
		.title {
			padding-top: 20px;
			text-align: left;
			line-height: 1.3;
			font-size: 30px; }
		.ul-d {
			.li-d {
				// vertical-align: top
				float: left;
				display: block;
				width: 49%;
				min-height: 114px;
				overflow: hidden;
				// &:last-of-type
				// 	width: 100%
				.wr-info-1,
				.wr-info-2,
				.wr-info-3,
				.wr-info-4,
				.wr-info-5 {
					max-width: 100%; }
				.bl-img,
				.name {
					height: 100%; }
				.bl-img {
					margin-bottom: 5px;
					min-height: 50px; }
				.bl-name {
					.name {
						font-weight: 400;
						height: auto; } } } } } }
