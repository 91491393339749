.fancybox-button--play,
.fancybox-button--fullscreen,
.fancybox-button--thumbs {
	display: none; }
.fancybox-button--close {
	@extend .ic.close;
	&:hover:not([disabled]) {
		background: transparent; }
	&:before,
	&:after {
		background-color: $c-white; } }
.fancybox-arrow {
	margin: auto;
	top: 0;
	bottom: 0;
	height: 100%;
	background: transparent;
	opacity: .35;
	transition: opacity .35s,transform .175s,-webkit-transform .175s;
	&:hover {
		opacity: 1; }
	&:active {
		-webkit-transform: scale(.95);
		-ms-transform: scale(.95);
		transform: scale(.95); }
	&:after,
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 2px;
		height: 30px;
		background-color: $c-white;
		transition: color .35s;
		content: ''; }
	&:after {
		-webkit-transform-origin: 50% 0%;
		-ms-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		margin-top: 15px; }
	&:before {
		-webkit-transform-origin: 50% 100%;
		-ms-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		margin-top: -15px; }
	&.fancybox-arrow--left:after,
	&.fancybox-arrow--left:before {
		margin-left: -10px; } }

.fancybox-arrow--right {
	&:after,
	&:before {
		margin-left: 10px; } }
.fancybox-arrow--left {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1); }

.fancybox-arrow--right:before,
.fancybox-arrow--left:after {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg); }

.fancybox-arrow--right:after,
.fancybox-arrow--left:before {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg); }
