.section.bl-error {
	.title {
		text-align: center;
		margin: auto;
		max-width: 1000px;
		padding-bottom: 50px; }
	.wr-swiper {
		.wr-control {
			margin-right:  426px;
			margin-bottom: 30px;
			overflow: hidden;
			.control {
				border: 1px solid $c-text;
				display: inline-block;
				padding: 10px 20px;
				width: auto;
				transition: .3s;
				&.active {
					background-color: $c-primary;
					color: $c-white;
					border-color: $c-primary; } } }
		.swiper-container {
			.li-d {
				.name {
					line-height: 29px;
					font-size: 24px;
					font-weight: 700; }
				.text {
					margin-right: 386px + 30px;
					line-height: 21px;
					margin-top: 5px; }
				.img {
					float: right;
					margin-left: 40px;
					max-width: 386px; } } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.bl-error {
		.title {
			padding-bottom: 10px;
			text-align: left; }
		.wr-swiper {
			.wr-control {
				margin: 0 -0.75rem;
				.p0 {
					padding: 0 .75rem; }
				.control {
					margin-bottom: 5px;
					text-align: center;
					width: 100%;
					&.active {} } }
			.swiper-container {
				.li-d {
					overflow: hidden;
					.name {
						line-height: 1.3;
						font-size: 20px;
						padding-bottom: 10px; }
					.text {
							margin: 0; }
					.img {
						float: none;
						margin-left: 0;
						width: auto;
						max-height: 200px; } } } } } }
