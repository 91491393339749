$bundle-bw: 3px;
$bundle-d: 12px + $bundle-bw * 2;
$nav-btn-d: 70px;
.section.sevenstep {
	.wr {
		max-width: 1110px; }
	.title {}
	.swiper-seven-step {
		.swiper-wrapper {
			.swiper-slide {
				.wr-content {
					max-width: 882px;
					text-align: center;
					margin: auto;
					.step {
						margin-top: 40px;
						line-height: 50px;
						color: $c-primary;
						font-size: 42px;
						text-align: center; }
					.name {
						margin-top: 10px;
						text-align: center;
						line-height: 29px;
						font-size: 24px;
						margin-bottom: 40px; }
					.img {
						max-height: 330px;
						float: right; }
					.row {
						position: relative;
						.dib-before {
							position: absolute;
							top: 0;
							bottom: 0;
							&:before {
								content: '';
								display: inline-block;
								height: 100%;
								width: 1%;
								vertical-align: middle; }
							.ul-d {
								display: inline-block;
								vertical-align: middle;
								max-width: 96%;
								.li-d {
									padding-left: 20px;
									margin: 10px 0;
									@extend .before;
									&:before {
										border-radius: 50%;
										border: 2px solid $c-gray-light;
										margin: auto;
										width: 8px;
										height: 8px;
										right: auto; } } } } } } } }
		.swiper-pagination {
			top: 20px;
			left: 0;
			right: 0;
			height: 2px;
			margin: auto;
			max-width: 420px;
			@extend .beaf;
			position: absolute;
			&:before,
			&:after {
				border: $bundle-bw solid $c-primary;
				border-radius: 50%;
				width: $bundle-d;
				height: $bundle-d;
				top: -8px; }
			&:before {
				left: - $bundle-d *2;
				right: auto;
				background-color: $c-primary; }
			&:after {
				left: auto;
				right: - $bundle-d *2; }
			.swiper-pagination-progressbar-fill {
				&:before {
					content: '';
					display: block;
					position: absolute;
					top: -1px;
					left: 0px;
					right: 0px;
					bottom: -1px;
					background-color: $c-primary; } } }
		.swiper-button-next,
		.swiper-button-prev {
			border-radius: 50%;
			width: $nav-btn-d;
			height: $nav-btn-d;
			background-color: $c-primary;
			position: absolute;
			&:before,
			&:after {
				background-color: $c-white;
				height: 2px;
				width: 24px;
				top: 50%; } }
		.swiper-button-next {
			&:before,
			&:after {
				margin-right: -20%; } }
		.swiper-button-prev {
			&:before,
			&:after {
				margin-left: -20%; } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.sevenstep {
		.wr {
			max-width: 100%; }
		.title {
			line-height: 1.3;
			font-size: 30px;
			text-align: left; }
		.swiper-seven-step {
			.swiper-wrapper {
				.swiper-slide {
					.wr-content {
						max-width: 75%;
						.step {
							line-height: 50px * .6;
							font-size: 42px * .6; }
						.name {
							margin-top: 5px;
							line-height: 29px * .8;
							font-size: 24px * .8;
							margin-bottom: 0; }
						.img {
							display: none; }
						.row {
							.dib-before {
								position: static;
								&:before {
									display: none; }
								.ul-d {
									display: inline-block;
									vertical-align: middle;
									max-width: 100%;
									.li-d {
										&:before {
											border-radius: 50%;
											border: 2px solid $c-gray-light;
											margin: auto;
											width: 8px;
											height: 8px;
											right: auto; } } } } } } } }
			.swiper-pagination {
				&:before,
				&:after {}
				&:before {}
				&:after {}
				.swiper-pagination-progressbar-fill {
					&:before {} } }
			.swiper-button-next,
			.swiper-button-prev {
				margin: 0;
				border-radius: 0;
				width: 10%;
				height: auto;
				top: 22px;
				bottom: 0;
				background-color: transparent;
				&:before,
				&:after {} }
			.swiper-button-next {
				right: 0;
				&:before,
				&:after {
					margin-right: -30%;
					background-color: $c-black; } }
			.swiper-button-prev {
				left: 0;
				&:before,
				&:after {
					background-color: $c-black;
					margin-left: -30%; } } } } }
