.section.postroil {
	padding-top: 70px;
	padding-bottom: 0;
	.wr {
		position: relative;
		background-image: url("#{$path-design}postroil.png");
		background-position: left bottom;
		background-repeat: no-repeat;
		background-size: contain; }
	.name-spec {
		position: absolute;
		bottom: 60px;
		right: 400px;
		line-height: 19px;
		font-weight: 700;
		span {
			font-weight: 300;
			font-size: 14px;
			font-style: italic; } }
	.bl-form {
		.title {}
		.text {}
		.form {
			.name {}
			.wr-it {} } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.postroil {
		padding-top: 10px;
		.wr {
			background: transparent; }
		.name-spec {
			display: none;
			span {} }
		.bl-form {
			.title {}
			.text {}
			.form {
				.name {}
				.wr-it {} } } } }
