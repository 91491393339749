$side-checkbox: 14px;

$wr-w: 1010px;
$wr-lr-p: 15px;

/*Header*/
$logo-w: 166px;
$logo-h: 89px;

$header-pt: 15px;
$header-pb: 0;
$header-content-h: $logo-h;
$header-h: $header-pt + $header-content-h + $header-pb;


/*Decoration*/
$border-weight: 1px;
$border-line: $border-weight solid $c-line;
$border-line-light: $border-weight solid $c-line-light;

/* Breadcrumbs */
$bc-dash-w: 5px;
$bc-dash-weight: $border-weight;
$bc-dash-area-w: 20px;

$brick-side: 32px;
$brick-border-weight: 2px;
$brick-border: $brick-border-weight solid $c-primary;

$path-img: '../img/';
$path-design: '#{$path-img}design/';

/* order.page-----------------*/
$margin-horizont-number: 30px;
$bl-info-ml: ( $margin-horizont-number + $brick-border-weight ) * 2 + $brick-side;

/*Badget*/
$badget-h: 20px;
$badget-w: $badget-h;

/*Catalog*/
$filter-popup-h: 50px;
$filter-popup-w: 260px;

/*Images*/
$img-min-h: 70px;
$img-min-w: 70px;


/*Card*/
$card-order-w: 272px;
$card-title-text-w: 170px;
$card-p-l: 20px;
$card-item-content-h: 110px;
$card-item-vp: 50px;
$card-item-h: $card-item-content-h + $card-item-vp * 2;

/*btn*/
$btn-h: 68px;
$btn-max-w: 210px;
$btn-weight-border: 2px;


/*Footer*/

$footer-pt: 50px;
$footer-bottom-line-h: 120px;
$footer-nav-h: 188px;
// $footer-h: $footer-pt + $footer-bottom-line-h + $footer-nav-h
$footer-h: 144px;


/*Front page*/
$big-img-w: 540px;
$big-img-h: 405px;
$own_prod-m: 12px;

/*Catalog page*/
$filter-w:  285px;
$wr-interval-w: 34px;

/*Text*/
/*h1*/
$fs-h1: 42px;
$lh-h1: 50px;

/*h2*/
$fs-h2: 34px;
$lh-h2: 41px;

/*h3*/
$fs-h3: 30px;
$lh-h3: 36px;

/*h4*/
$fs-h4: 24px;
$lh-h4: 29px;

/*h5*/
$fs-h5: 20px;
$lh-h5: 24px;

/*h6*/
$fs-h6: 18px;
$lh-h6: 22px;
