.section.excursion {
	padding-top: 70px;
	padding-bottom: 0;
	background-color: #C7B299;
	.wr {
		position: relative;
		.main-img {
			position: absolute;
			top: 0;
			right: 504px;
			bottom: 0;
			margin: auto; }
		.bl-form {
			.title {}
			.text {}
			.form {
				.name {}
				.wr-it {}
				.wr-switcher {
					.col {
						@include distance(14px); }
					.ir-d:checked+.switcher {
						background-color: $c-primary-dark;
						color: $c-white;
						text-decoration: none; }
					.switcher {
						cursor: pointer;
						display: block;
						line-height: 50px;
						text-align: center;
						background-color: $c-white;
						transition: .3s; } } } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.excursion {
		padding-top: 20px;
		.wr {
			.main-img {
				display: none; }
			.bl-form {
				.title {}
				.text {}
				.form {
					.name {}
					.wr-it {}
					.wr-switcher {
						margin-bottom: 10px;
						.col {
							@include distance(10px); }
						.ir-d:checked+.switcher {}
						.switcher {} } } } } } }
