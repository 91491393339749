.section.firstscreen {
	background: url("#{$path-design}bg-firstscreen.jpg") right center no-repeat;
	background-size: 50% auto;
	.wr {
		.title {
			text-align: left;
			line-height: 58px;
			font-size: 48px;
			font-weight: 700;
			margin-top: 40px;
			margin-bottom: 30px;
			padding-bottom: 0; }
		.line-left {
			border-left: 2px solid $c-primary;
			padding-left: 15px;
			line-height: 21px;
			max-width: 300px;
			float: left; }
		.wr-bundle {
			position: relative;
			.bubble {
				position: absolute;
				right: 50%;
				margin-right: -108px;
				margin-top: -20px;
				float: left;
				border-radius: 50%;
				background-color: $c-primary;
				height: 108px;
				width: 108px;
				padding: 20px 10px;
				text-align: center;
				color: $c-white;
				span {
					font-size: 42px;
					letter-spacing: 1px;
					line-height: 42px;
					font-weight: 700; } } }
		.a-btn {
			margin-top: 50px;
			width: 100%;
			max-width: 320px; }
		.text {
			line-height: 19px;
			font-size: 16px;
			position: relative;
			max-width: 320px;
			.arrow {
				background: url("#{$path-design}arrow.png");
				position: absolute;
				right: -46px;
				top: -30px;
				height: 93px;
				width: 73px;
				transform: rotate(-155deg); } }
		.ul-d {
			margin: {
				top: 70px;
				left: 244px; }
			clear: both;
			.li-d {
				float: left;
				position: relative;
				span {
					position: absolute;
					top: 0;
					left: 0;
					font-size: 124px;
					color: $c-primary;
					text-align: center;
					font-weight: 700;
					opacity: .2; }
				&.li-nn-1 {
					max-width: 190px; }
				&.li-nn-2 {
					margin: 0 45px;
					max-width: 195px; }
				&.li-nn-3 {
					max-width: 285px; } } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.firstscreen {
		background: transparent;
		.wr {
			.title {
				font-size: 48px * .5 !important;
				line-height: 1.1 !important;
				padding: 0;
				margin-top: 0; }
			.line-left {
					float: none; }
			.wr-bundle {
				margin: 0;
				.bubble {
					padding: 11px 0;
					height: 85px;
					width: 85px;
					position: static;
					margin: 10px auto;
					float: none;
					font-size: 14px;
					line-height: 1;
					span {
						font-size: 34px;
						line-height: 1; } } }
			.a-btn {
				margin: 0;
				max-width: 100%; }
			.text {
				.arrow {
					display: none; } }
			.ul-d {
				margin: 10px 0 0;
				.li-d {
					padding: 0 0 10px 30px;
					float: none;
					max-width: 100%;
					text-align: left;
					margin: 0 !important;
					margin-bottom: 5px !important;
					span {
						font-size: 30px;
						line-height: 27px;
						margin-right: 10px; }
					&.li-nn-1 {
						max-width: 100%; }
					&.li-nn-2 {
						max-width: 100%; }
					&.li-nn-3 {
						max-width: 100%; } } } } } }
