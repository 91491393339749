$wr-main-info-w: 430px;
.section-bl-img-catalog {
	position: relative;
	width: 100%;
	.wr-bl-img-catalog {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		.bl-img-catalog {
			position: absolute;
			width: 76%;
			top: 0;
			bottom: 0;
			right: $wr-main-info-w;
			left: -168px;
			background: url(#{$path-design}katalog.png) center bottom no-repeat;
			background-size: contain;
			.update {
				position: absolute;
				bottom: 11%;
				text-align: center;
				width: 100%;
				font-size: 14px; } } }
	.form {
		margin-top: -145px;
		max-width: $wr-main-info-w;
		float: right;
		background-color: $c-bg-gray;
		padding: 53px 39px 32px 40px;
		.title {
			margin: 0;
			padding: 0;
			padding-bottom: 10px;
			line-height: 32px;
			font-size: 29px; }
		.text {
			margin-bottom: 30px;
			position: relative;
			.arrow {
				background: url("#{$path-design}arrow.png");
				position: absolute;
				left: -95px;
				top: -165px;
				height: 93px;
				width: 73px;
				transform: rotate(-15deg); } }
		.count-download {
			text-align: center;
			margin-bottom: 0;
			span {
				font-weight: 700; } } } }
.section.onescreen {
	padding-bottom: 0;
	.wr {
		.title {
			position: relative;
			z-index: 2;
			text-align: left;
			line-height: 58px;
			font-size: 48px;
			font-weight: 700;
			margin-bottom: 30px;
			padding-bottom: 0; }
		.line-left {
			position: relative;
			z-index: 2;
			border-left: 2px solid $c-primary;
			padding-left: 15px;
			line-height: 26px;
			font-size: 20px;
			float: left; }
		.a-btn {
			margin-top: 50px; }
		.text {
			line-height: 19px;
			font-size: 16px; } } }
@media #{$medium-and-down} {
	.section-bl-img-catalog {
		.wr-bl-img-catalog {
			position: relative;
			.bl-img-catalog {
				display: none;
				.update {} } }
		.form {
			background-color: transparent;
			margin-top: 0;
			max-width: 100%;
			float: none;
			padding: 0;
			.title {
				padding-bottom: 10px;
				line-height: 32px * .8;
				font-size: 29px * .8; }
			.text {
				margin-bottom: 30px;
				.arrow {
					display: none; } }
			.count-download {
				span {} } } }
	.section.onescreen {
		.wr {
			.title {
				margin-bottom: 30px; }
			.line-left {
				padding-left: 15px;
				line-height: 26px;
				font-size: 20px;
				float: none; }
			.a-btn {
				margin-top: 50px; } } } }
@media #{$small-and-down} {}
