#popup-test {
	background: $c-white url('#{$path-design}bg-popup-test.jpg') left center no-repeat;
	.wr-content {
		padding: 80px 40px 60px;
		max-width: 380px;
		float: right;
		width: 100%;
		.title {
			text-align: left;
			line-height: 40px;
			font-size: 36px;
			border-left: 5px solid $c-primary;
			margin-bottom: 30px;
			padding: 0 0 0 20px; }
		&>.text {
			padding-left: 25px;
			line-height: 26px;
			font-size: 20px; }
		.btn {
			margin-top: 50px; }
		.label {
			text-align: center;
			margin-top: 5px;
			line-height: 18px;
			font-size: 14px; }
		.bl-gift {
			max-width: 214px;
			margin: 50px auto 0;
			padding-left: 70px;
			background-position: left center;
			background-size: 50px 50px;
			position: relative;
			.text {
				line-height: 20px; }
			.arrow {
				background: url('#{$path-design}arrow.png');
				position: absolute;
				right: -32px;
				top: -50px;
				height: 93px;
				width: 73px;
				transform: rotate(-113deg); } } } }
