.btn {
	width: 100%; }
.btn {
	&,
	&:hover,
	&:active,
	&:visited,
	&:focus {
		text-transform: none;
		display: inline-block;
		text: {
			align: center;
			decoration: none; }
		min-height: $btn-h;
		line-height: $btn-h;
		font-size: 16px;
		background-color: $c-btn;
		border-radius: 0;
		border: 0;
		margin: 0;
		color: $c-white;
		overflow: hidden;
		position: relative;
		box-shadow: none;
		transition: .3s;
		&:hover,
		&:active {
			background-color: $c-btn-hover;
			box-shadow: none; }
		&.v1 {
			background-color: $c-btn-white;
			color: $c-text;
			position: relative;
			border: $border-line;
			&:hover,
			&:active {
				background-color: $c-btn-white-hover;
				color: $c-white; } }
		&.vzd {
			box-shadow: 0px 8px 14px $c-btn-box-shadow;
			&:active {
				box-shadow: none; } }
		&.v2 {
			background-color: $c-yellow;
			color: $c-black !important;
			border: 0;
			&:hover {
				background-color: $c-yellow-dark; } }
		&.error {
			background-color: $c-red; }
		color: $c-white; } }

.a-btn {
	padding: 0 25px;
	width: auto;
	height: auto;
	color: $c-white;
	&.v1 {
		&:hover {
			color: $c-primary; } } }

@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.btn {
		&,
		&:hover,
		&:active,
		&:visited,
		&:focus {
			// min-height: 52px
			// line-height: 52px
			// font-size: 14px
			// padding: 0 10px
			width: 100%;
			&:hover,
			&:active {}
			&.v1 {
				&:hover,
				&:active {} }
			&.vzd {
				&:active {} }
			&.v2 {
				&:hover {} }
			&.error {} } }
	.a-btn {
		&.v1 {
			&:hover {} } } }
