$wr-tab-control-w:  260px;
$user-h: 50px;
$user-w: $user-h;
.section.postroili {
	.wr {
		.title {
			padding-bottom: 0;
			line-height: 50px;
			font-size: 42px;
			font-weight: 700; }
		.text {
			text-align: center; }
		.wr-postroil {
			margin-top: 30px;
			.ul-d.wr-control {
				max-width: $wr-tab-control-w;
				width: 100%;
				border: $border-line-light;
				float: left;
				.li-d.control {
					cursor: pointer;
					padding: 36px 10px 14px 76px;
					padding: 0 10px 0 76px;
					border-bottom: $border-line-light;
					position: relative;
					.a-d {
						span {
							line-height: 72px;
							height: 72px; } }
					&:hover,
					&.active {
						background-color: $c-gray-light-light;
						* {
							color: $c-text; } }
					&.active {
						@extend .before;
						&:before {
							left: auto;
							background: url('#{$path-design}t-active.png') center center no-repeat;
							width: 17px;
							right: -14px; } }
					.bl-img {
						position: absolute;
						top: 18px;
						left: 13px;
						border-radius: 50%;
						overflow: hidden;
						max-width: $user-w;
						min-height: $user-h; }
					.name {
						display: block;
						line-height: 21px;
						font-size: 11px;
						font-weight: 700;
						text-transform: uppercase; }
					.text {
						display: block;
						text-align: left;
						line-height: 21px;
						font-size: 11px; } } }
			.wr-tab.swiper-container {
				margin-left: $wr-tab-control-w + 19px + 2* $border-weight;
				max-width: 600px;
				margin: auto;
				.tab.swiper-slide {
					.wr-swiper {
						.swiper-container {
							.swiper-wrapper {
								.swiper-slide {
									.img {} } } }
						.wr-thumb {
							.bl-img {}
							.border-thumb {} } }
					.name {
						line-height: 24px;
						font-size: 20px;
						font-weight: 700; }
					.ul-d {
						max-width: 280px;
						margin-top: 15px;
						.li-d {
							margin-top: 5px;
							span {
								font-weight: 700;
								float: right; } } }
					.bl-review {
						.name {
							margin-top: 30px; }
						.wr-bl-text-more {
							height: auto;
							.text {
								margin-top: 10px;
								text-align: left;
								line-height: 19px;
								height: auto;
								overflow: hidden;
								position: relative;
								transition: .3s;
								&:before {
									content: '';
									display: block;
									position: absolute;
									bottom: 0;
									z-index: 10;
									width: 100%;
									height: 0;
									transition: .3s;
									background: rgba(255,255,255,0);
									background: -webkit-gradient(left top,left bottom,color-stop(0,rgba(255,255,255,0)),color-stop(3%,rgba(255,255,255,0)),color-stop(70%,rgba(255,255,255,.7)),color-stop(100%,rgba(255,255,255,.7)));
									background: -webkit-linear-gradient(top,rgba(255,255,255,0) 0,rgba(255,255,255,0) 3%,rgba(255,255,255,.7) 70%,rgba(255,255,255,.7) 100%);
									background: linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,0) 3%,rgba(255,255,255,.7) 70%,rgba(255,255,255,.7) 100%);
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(255,255,255,.7)', endColorstr='rgba(255,255,255,.7)', GradientType=0 ); } }
							&.active {
								height: 150px !important;
								.text {
									height: 100px !important;
									&:before {
										height: 50px; } } }
							.btn-more {
								text-align: right;
								color: $c-primary; } } } } } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.postroili {
		.wr {
			.title {
				line-height: 1.3;
				font-size: 30px;
				text-align: left; }
			.text {
				text-align: left; }
			.wr-postroil {
				.ul-d.wr-control {
					max-width: 100%;
					float: none;
					.li-d.control {
						cursor: pointer;
						padding: 10px;
						border-bottom: $border-line-light;
						position: relative;
						&:hover,
						&.active {
							background-color: $c-gray-light-light; }
						&.active {
							&:before {
								display: none; } }
						.bl-img {}
						.name {}
						.text {} } }
				.wr-tab.swiper-container {
					margin-left: 0;
					max-width: 100%;
					.tab.swiper-slide {
						.wr-swiper {
							.swiper-container {
								.swiper-wrapper {
									.swiper-slide {
										.img {} } } }
							.wr-thumb {
								.bl-img {
									max-width: 140px; }
								.border-thumb {} } }
						.name {
							line-height: 1.3; }
						.ul-d {
							max-width: 100%;
							.li-d {
								span {} } }
						.bl-review {
							.name {
								margin-top: 10px; }
							.wr-bl-text-more {
								height: auto;
								.text {
									line-height: 1.3;
									&:before {} }
								&.active {
									.text {
										&:before {} } }
								.btn-more {
									margin-top: 10px; } } } } } } } } }
