$wr-main-info-w: 360px;
.iziModal {
	.close-izi {
		&:hover {} }
	.wr-content {
		padding-top: 50px;
		.bl-bl {
			padding: 0 50px;
			.wr-main-info {
				float: right;
				width: 100%;
				max-width: $wr-main-info-w;
				.name-project {
					line-height: 36px;
					font-size: 30px; }
				.ic {
					margin: 0; }
				.info {
					margin-top: 15px;
					box-shadow: 0px 0px 8px rgba(127,127,127,0.15),0px 0px 8px rgba(127,127,127,0.15);
					border: 1px solid #FEFEFE;
					padding: 18px 0 18px 24px;
					.row {
						.col {
							float: left;
							display: inline-block;
							width: auto;
							margin-right: 29px;
							.name {
								margin-bottom: 5px;
								font-size: 14px; }
							.wr-ic-text {
								font-weight: 700;
								line-height: 19px;
								vertical-align: middle;
								.ic {
									vertical-align: middle;
									margin-right: 5px; } } } } }
				.bl-price {
					font-size: 14px;
					.ic {
						margin-right: 5px; }
					.price {
						padding-left: 30px;
						margin-top: 5px;
						line-height: 23px;
						font-size: 19px;
						color: $c-primary;
						font-weight: 700; } }
				.wr-form {
					padding: 0 0 0 18px;
					max-width: 100%;
					float: none;
					.title-form {
						margin-top: 30px;
						line-height: 26px;
						font-size: 22px;
						font-weight: 700; }
					.text {
						font-size: 14px;
						line-height: 17px;
						margin-top: 20px; }
					.form {
						margin-top: 20px;
						.name {
							font-size: 14px;
							line-height: 17px;
							margin-bottom: 5px; }
						.btn {
							margin-top: 5px; } } } }
			.wr-photo {
				margin-right: $wr-main-info-w;
				padding-right: 50px;
				.wr-swiper {
					.swiper-container {
						.swiper-wrapper {
							.swiper-slide {} } }
					.wr-thumb {
						margin: {
							left: -5px;
							right: -5px; }
						.bl-img {
							margin: 5px;
							max-width: 100px;
							min-height: 75px; } } }
				.plan {
					.name {
						line-height: 29px;
						font-size: 24px;
						margin-bottom: 20px; }
					.row {
						.col {
							.img {
								margin: 0; } } } } }
			&.middle {
				border-top: $border-line;
				padding-top: 30px;
				.title-list {
					line-height: 29px;
					font-size: 24px; }
				.ul-d {
					margin-bottom: 30px;
					.li-d {
						margin-top: 25px;
						.name,
						.text {
							line-height: 18px;
							font-size: 14px; }
						.name {
							font-weight: 700; } } } }
			&.last {
				padding: 50px;
				float: none;
				max-width: 100%;
				.wr {
					max-width: 750px;
					.form {
						max-width: 100%;
						.wr-it {
							.it {} } }
					.title-last {
						line-height: 29px;
						font-size: 24px;
						text-align: center;
						font-weight: 700; }
					.text {
						margin-top: 20px;
						margin-bottom: 5px; }
					.col {
						@include distance(14px); } } } } } }

@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.iziModal {
		.close-izi {}
		.wr-content {
			padding-top: 40px;
			.bl-bl {
				padding: 0 10px;
				.wr-main-info {
					float: none;
					max-width: 100%;
					.name-project {
						line-height: 1.3;
						font-size: 16px;
						font-weight: 700; }
					.ic {}
					.info {
						margin-top: 10px;
						padding: 10px 0 10px 10px;
						.row {
							.col {
								margin-right: 15px;
								.name {}
								.wr-ic-text {
									.ic {} } } } }
					.bl-price {
						.ic {}
						.price {} }
					.wr-form {
						padding: 0;
						.title-form {
							margin-top: 15px;
							line-height: 1.3;
							font-size: 16px; }
						.text {
							margin-top: 10px;
							line-height: 1.3;
							font-size: 20px; }
						.form {
							margin-top: 10px;
							.name {}
							.btn {} } } }
				.wr-photo {
					margin-right: 0;
					padding-right: 0;
					.gallery {
						.swiper-container {
							.swiper-wrapper {
								.swiper-slide {} } }
						.wr-thumb {
							.bl-img {} } }
					.plan {
						.name {}
						.row {
							.col {
								.img {} } } } }
				&.middle {
					.title-list {}
					.ul-d {
						.li-d {
							margin-top: 10px;
							.name,
							.text {
								line-height: 1.3;
								font-size: 20px; }
							.name {
								.br {
									display: inline-block;
									width: 5px; } } } } }
				&.last {
					padding: 15px;
					.wr {
						max-width: 100%;
						padding: 0;
						.form {
							.wr-it {
								.it {} } }
						.title-last {
							line-height: 1.3;
							font-size: 16px; }
						.text {}
						.col {
							padding: 0 !important; } } } } } } }
