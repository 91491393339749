.section.pereplata {
	.wr {
		max-width: 938px;
		.title {
			text-align: center;
			line-height: 50px;
			font-size: 42px;
			padding-bottom: 0;
			span {
				font-size: 140px;
				color: $c-primary;
				line-height: 168px;
				span {
					font-size: 84px;
					line-height: 101px; } } }
		.text {} } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.pereplata {
		.wr {
			.title {
				text-align: left;
				line-height: 1.3;
				font-size: 30px;
				span {
					line-height: 1.3;
					font-size: 30px;
					span {
						line-height: 1.3;
						font-size: 30px; } } }
			.text {
				text-align: left; } } } }
