body.thanks-catalog {
	background-color: $c-bg-gray; }
.section.thanks-catalog {
	.wr {
		max-width: 1000px;
		.title {
			text-align: left;
			font: {
				size: 40px;
				weight: 600; }
			line-height: 48px;
			padding-bottom: 10px;
			margin-bottom: 0; }
		.title.text {
			color: $c-orange;
			font-size: 80px;
			line-height: 92px;
			margin: {
				top: 0;
				bottom: 35px; } }
		.wr-step {
			margin-bottom: 60px;
			.step {
				display: block;
				border-radius: 10px;
				padding: 0 22px;
				background-color: $c-white;
				@extend .zd-1;
				.name {
					display: block;
					font-size: 32px;
					line-height: 85px;
					border-bottom: 2px solid $c-gray-light;
					text-align: right;
					color: $c-gray-light;
					margin-bottom: 13px;
					font-weight: 600; }
				.text {
					font-size: 17px;
					line-height: 24px;
					margin-bottom: 15px;
					min-height: 105px;
					display: block;
					text-align: left; } } }
		.wr-email {
			margin: auto;
			max-width: 700px;
			border: $border-line;
			border-radius: 5px;
			.li-d {
				.a-d {
					border-right: $border-line;
					text-align: center;
					padding: 15px 0;
					.name {
						line-height: 34px; }
					.ic {
						height: 34px;
						width: 34px; } }
				&:last-of-type {
					.a-d {
						border-right: 0; } } } }
		.text-gray {
			margin-top: 40px;
			color: $c-gray-light;
			text-align: center;
			font-size: 16px;
			line-height: 24px; } } }
