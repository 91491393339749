.section.thanks {
	.wr {
		h1.title {
			font-size: 40px;
			line-height: 1.3;
			padding-bottom: 10px; }
		.text {
			padding-bottom: 10px;
			text-align: center;
			font-size: 30px;
			line-height: 1.3;
			&.line-1 {}
			&.line-2 {
				font-size: .8em; } } } }
