.section.we-build {
	.wr {
		.title {
			line-height: 50px;
			font-size: 42px;
			text-align: center;
			font-weight: 700;
			max-width: 645px;
			margin: auto;
			padding-bottom: 0; }
		&>.text {
			text-align: center; }
		.wr-control {
			margin-top: 75px;
			.control {
				&.active {
					color: $c-primary; }
				.bl-img {
					max-width: 169px;
					min-height: 136px;
					background-size: auto; }
				.name {
					display: block;
					text-align: center; } } }
		.swiper-container {
			margin-top: 50px;
			.swiper-slide {
				.ul-d {
					padding-left: 50%;
					background: transparent left center no-repeat;
					.li-d {
						margin-bottom: 10px;
						.name {
							font-weight: 700; }
						.text {
							margin-top: 5px;
							line-height: 19px; } } }
				.wr-sert {
					margin-top: 40px;
					.item-sert {
						max-width: 109px;
						float: left;
						margin: 0 9px;
						&:first-of-type {
							margin-left: 0; }
						&:last-of-type {
							margin-right: 0; }
						.img {} } } } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.we-build {
		.wr {
			.title {
				line-height: 1.3;
				font-size: 30px;
				text-align: left; }
			&>.text {
				text-align: left; }
			.wr-control {
				margin-top: 15px;
				.control {
					margin-bottom: 10px;
					&.active {}
					.bl-img {
						min-height: 56px;
						max-width: 98px;
						background-size: contain; }
					.name {
						margin-top: 5px;
						line-height: 56px; } } }
			.swiper-container {
				margin-top: 0;
				.swiper-slide {
					.ul-d {
						padding-left: 0;
						background: transparent !important;
						.li-d {
							margin-bottom: 10px;
							.name {
								font-weight: 700; }
							.text {
								margin-top: 5px;
								line-height: 1.3; } } }
					.wr-sert {
						margin-top: 20px;
						text-align: center;
						.item-sert {
							max-width: initial;
							display: block;
							height: 150px;
							margin: 0 !important;
							float: none;
							.img {
								margin: auto; } } } } } } } }
