.section.calc {
	.wr {
		.title {
			padding-bottom: 0;
			line-height: 50px;
			font-size: 42px; }
		.text {
			text-align: center; }
		.form {
			max-width: 100%; }
		.ul-d {
			.li-d {
				.name {
					margin: 30px 0 20px;
					text-align: center;
					display: block;
					line-height: 24px;
					font-size: 20px; }
				&.calc-1 {
					.row {
						.ir-d:checked+.col {
							border-color: $c-primary; }
						.col {
							cursor: pointer;
							transition: .3s;
							text-align: center;
							padding: 10px;
							border: 1px solid $c-white;
							.bl-img {
								max-width: 169px;
								min-height: 136px; }
							.name {
								margin: 0;
								line-height: 19px;
								font-size: 16px; } } } }
				&.calc-2 {
					.rows {
						width: 100%;
						text-align: center;
						.ir-d:checked+.col {
							background-color: $c-primary;
							color: $c-white; }
						.col {
							cursor: pointer;
							border: 1px solid $c-primary;
							margin: 0 20px;
							width: 151px;
							text-align: center;
							display: inline-block;
							height: 101px;
							transition: .3s;
							&:before {
								content: '';
								display: inline-block;
								height: 100%;
								width: 1%;
								vertical-align: middle; }
							.wrp {
								line-height: 16px;
								display: inline-block;
								vertical-align: middle;
								width: 99%; } } } }
				&.calc-3 {
					max-width: 690px;
					margin: 60px auto;
					.area-min,
					.area-max {
						margin-top: 20px;
						line-height: 19px; }
					.area-min {
						margin-left: -12px;
						float: left; }
					.area-max {
						float: right;
						margin-right: -12px; }
					#interval {
						margin-top: 50px;
						height: 5px;
						background-color: $c-primary;
						box-shadow: none;
						border-radius: 10px;
						.noUi-base {
							.noUi-handle.noUi-handle-lower {
								top: -10px;
								left: -12px;
								border: 0;
								border-radius: 50%;
								width: 25px;
								height: 25px;
								background-color: $c-primary;
								box-shadow: none;
								cursor: pointer;
								&:before,
								&:after {
									display: none; }
								.noUi-tooltip {
									// opacity: 0
									transition: .3s; }
								&:active {
									.noUi-tooltip {
										opacity: 1; } } } } } } } }
		.btn-result {
			max-width: 320px;
			margin: auto;
			display: block; }
		.result {
			display: none;
			margin-top: 60px;
			.noUi-horizontal {
				.noUi-handle {
					background-color: $c-primary; } }
			&>.name {
				line-height: 29px;
				font-size: 24px;
				text-align: center;
				margin-bottom: 20px;
				span {
					font-weight: 700; } }
			.text {
				line-height: 22px;
				font-size: 18px;
				font-style: italic;
				margin-bottom: 50px; }
			.row {
				margin: auto;
				.col {
					.wr-bl-img {
						max-width: 47px;
						margin: 0 auto 15px;
						.bl-img {
							min-height: 1px;
							padding-bottom: 100%; } }
					.name {
						text-align: center;
						margin: auto;
						line-height: 19px; } } }
			.form {
				margin: 40px auto 0;
				max-width: 716px;
				.name {
					margin-bottom: 5px;
					font-size: 14px; }
				.row {
					.col {
						@include distance(14px);
						.wr-it {} } }
				.counter {
					margin-top: 15px;
					line-height: 19px;
					span {
						float: right;
						font-weight: 700; } } } } } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.calc {
		.wr {
			.title {
				padding-bottom: 0;
				line-height: 1.3;
				font-size: 30px;
				text-align: left; }
			.text {
				text-align: left; }
			.ul-d {
				.li-d {
					.name {
						text-align: left;
						line-height: 1.3; }
					&.calc-1 {
						.row {
							.ir-d:checked+.col {}
							.col {
								text-align: center;
								padding: 5px;
								.bl-img {
									max-width: 100%;
									min-height: 66px; }
								.name {
									text-align: center;
									line-height: 17px;
									font-size: 14px; } } } }
					&.calc-2 {
						.rows {
							.ir-d:checked+.col {}
							.col {
								margin: 5px 0;
								width: 100%;
								text-align: center;
								display: block;
								height: auto;
								padding: 10px 0;
								&:before {}
								.wrp {
									.br {
										display: inline-block;
										width: 5px; } } } } }
					&.calc-3 {
						max-width: 100%;
						margin: 10px auto 39px;
						.area-min,
						.area-max {
							margin-top: 10px;
							line-height: 19px; }
						.area-min {
							margin-left: -12px;
							float: left; }
						.area-max {
							float: right;
							margin-right: -12px; }
						#interval {
							.noUi-base {
								.noUi-handle.noUi-handle-lower {
									&:before,
									&:after {}
									.noUi-tooltip {}
									&:active {
										.noUi-tooltip {
											opacity: 1; } } } } } } } }
			.btn-result {}
			.result {
				margin-top: 20px;
				.noUi-horizontal {
					.noUi-handle {} }
				&>.name {
					line-height: 29px * .8;
					font-size: 24px * .8;
					margin-bottom: 10px;
					span {
						font-weight: 700; } }
				.text {
					margin-bottom: 10px;
					text-align: center; }
				.row {
					.col {
						.wr-bl-img {
							margin: 10px auto 5px;
							.bl-img {
								min-height: 1px;
								padding-bottom: 100%; } }
						.name {} } }
				.form {
					.name {}
					.row {
						.col {
							padding: 0 !important;
							.wr-it {} } }
					.counter {
						span {
							display: block;
							float: none; } } } } } } }
