/* common-section -------------------------------------------------------------------------------*/

html {
	height: 100%; }
html, body {
	width: 100%;
	margin: 0; }

h1, h2, h3, h4, h5, h6, p, li, body {
	font: {
		family: 'Gotham Pro', Arial, Helvetica Neue, Helvetica, sans-serif;
		weight: 400;
		size: 16px;
		feature-settings: 'liga'; }
	-moz-font-feature-settings: 'liga';
	text: {
		align: left;
		rendering: optimizeLegibility; }
	line-height: 19px;
	word-wrap: break-word;
	color: $c-text; }

p {
	margin: 8px 0; }
p,
li,
.text,
.name,
.a-d,
.li-d {
	line-height: 24px;
	font-size: 16px;
	text-align: left;
	font-weight: 400; }

b,
strong {}
.b {
	font-weight: 700; }


a,
input,
button,
textarea {
	&,
	&:active,
	&:hover,
	&:focus {
		box-sizing: border-box;
		outline: none !important;
		background-color: transparent; } }
button {
	padding: 0; }



ul:not(.browser-default) {
	padding-left: 24px;
	&,
	& li {
		list-style-type: disc; } }
a {
	color: $c-link;
	text-decoration: underline;
	&:focus,
	&:hover {
		color: $c-link-hover; } }


img {
	max-width: 100%;
	height: auto;
	max-height: 100%; }

nav {
	height: auto;
	line-height: normal;
	box-shadow: none;
	background: transparent; }
