.section.contact {
	padding: 0;
	.wr {
		text-align: center;
		margin-bottom: -55px;
		z-index: 1;
		position: relative;
		.title {
			padding-bottom: 0; }
		.text {
			text-align: center; }
		.phone {
			display: inline-block;
			margin: 30px 0;
			text-align: center;
			color: $c-primary;
			line-height: 73px;
			font-size: 61px; }
		.wr-info {
			text-align: center;
			background-color: $c-bg-gray;
			border: $border-line;
			padding: 35px 0;
			overflow: hidden;
			.ic {
				width: 28px;
				height: 28px;
				margin-bottom: 10px; }
			.name {
				text-align: center;
				color: $c-primary;
				line-height: 19px;
				margin-bottom: 5px; }
			.text {
				line-height: 18px;
				font-size: 14px; } } }
	#maps-section {
		min-height: 500px; } }
@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.section.contact {
		.wr {
			margin-bottom: 0;
			.title {
				line-height: 1.3;
				font-size: 30px; }
			.text {}
			.phone {
				margin: 10px 0;
				line-height: 30px;
				font-size: 25px; }
			.wr-info {
				padding: 15px 0;
				.ic {}
				.name {
					line-height: 17px; }
				.text {
					margin-bottom: 10px; } } } } }
